.containerBadgeFile {
  display: flex;
  align-items: center;
  padding: 0.1vw 1vw 0.1vw 1vw;
  border: 1px solid #94979a;
  border-radius: 20px;
  cursor: pointer;
}

.fileName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.iconBadgeFile {
  margin-right: 1vw;
  font-size: 12pt;
  color: tomato;
}

.containerBadges {
  display: grid;
  grid-template-columns: repeat(4, 25%);
  column-gap: 1vw;
}

.containerBadges a,
.containerBadges a:hover,
.containerBadges a:focus {
  color: #94979a;
  text-decoration: none;
}
