.container {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  height: 300px;
}

.containerLegend {
  display: flex;
  align-items: center;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}

.textLegend {
  margin-right: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-overflow: clip;
  white-space: nowrap;
}
