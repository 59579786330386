.containerFiltro {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2vh;
}
.containerData {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 1em;
}
