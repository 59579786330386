.containerBadge {
  display: flex;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  line-height: 1;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  background-color: #777;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 13vw;
}
