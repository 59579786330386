.containerLoadingCount {
  display: flex;
  justify-content: center;
  align-items: center;
}

.circleContainerCount {
  display: flex;
  width: 25px;
  flex-wrap: wrap;
}

.circleWhite {
  position: relative;
  width: calc(25px / 2);
  height: calc(25px / 2);
  opacity: 0;
}
.circleWhite::before {
  content: '';
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.circleWhite-1 {
  animation: animate-circle 2s ease-in-out infinite;
}
.circleWhite-1::before {
  background: #fff;
  bottom: calc(-20px / 2);
  right: calc(-20px / 2);
}
.circleWhite-2 {
  animation: animate-circle 2s ease-in-out infinite;
}
.circleWhite-2::before {
  background: #fff;
  bottom: calc(-20px / 2);
  left: calc(-20px / 2);
}
.circleWhite-3 {
  animation: animate-circle 2s ease-in-out infinite;
}
.circleWhite-3::before {
  background: #fff;
  top: calc(-20px / 2);
  right: calc(-20px / 2);
}
.circleWhite-4 {
  animation: animate-circle 2s ease-in-out infinite;
}
.circleWhite-4::before {
  background: #fff;
  top: calc(-20px / 2);
  left: calc(-20px / 2);
}

@keyframes animate-circle {
  0% {
    transform: rotate(0);
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    transform: rotate(360deg);
    opacity: 0;
  }
}
