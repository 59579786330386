.containerFooter {
  position: relative;
  bottom: 0;
  left: 0vw;
  width: 83vw;
  height: 6vh;
  background: #666;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
