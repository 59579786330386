.containerCashback {
  display: grid;
  grid-template-columns: repeat(2, 49%);
  column-gap: 2%;
}

.containerButton {
  display: grid;
  grid-template-columns: 88% 10%;
  column-gap: 2%;
  align-items: self-end;
}
