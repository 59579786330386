.containerAttachment {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #f4f4f4;
}

.headerAttachment {
  width: 100%;
  display: flex;
  justify-content: center;
  background: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 0.5vw;
  font-size: 55px;
  color: #666;
}

.footerAttachment {
  width: 100%;
  padding: 0.5vw;
  background: #f4f4f4;
  font-weight: 700;
}

.footerAttachment a,
.footerAttachment a:hover,
.footerAttachment a:focus {
  color: #666;
  cursor: pointer;
}
