.rootMessageList {
  height: 65vh;
}

.chatList {
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 !important;
  height: 62vh;
}

.fiberRecord {
  animation: fading 1.5s infinite;
  color: indianred;
}

@keyframes fading {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* h2{ height:48px;margin:0;padding:0} */
/* h2{
  -webkit-animation: fade;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
} */
