.swiper-button-next,
.swiper-button-prev {
  color: #36325e !important;
  padding: 10px;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  width: 38px;
  height: 38px;
  transition: all .3s ease;
  border: 1px solid #CFCFCF;
  transform: scale(.8);
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 22px;
}
