.container-menu {
  z-index: 99999 !important;
  width: 15vw;
  background-color: #0064ad;
  position: fixed;
  left: 4vw;
  padding: 1vh 1vw;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.container-menu > strong {
  text-align: justify;
}

.container-menu > strong:after {
  content: "";
  display: inline-block;
  width: 100%;
}
