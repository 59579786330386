.listFiles {
  display: grid;
  grid-template-rows: auto auto auto;
  row-gap: 2%;
  grid-gap: 1vh 1vh;
  margin-top: 2vh;
}

.listFilesImages {
  display: grid;
  height: 100%;
  grid-template-rows: auto auto auto;
  grid-gap: 2vh 2vh;
  row-gap: 4%;
  margin-top: 2vh;
  border-radius: 10px;
  margin-bottom: 10%;
}

.imgSection {
  display: flex;
  flex-direction: initial;
  margin-top: 5px;
}

.img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
}

.btnRemove {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  cursor: inherit;
  display: block;
  background-color: #fff
}

.nameFile {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
}
