.contSection {
  display: grid;
  grid-template-columns: 35% 65%;
  text-align: center;
}

.contDescricao {
  padding: 15px 5px;
}

.contDescricao > span {
  font-size: 9pt;
}

.contIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24pt;
  color: rgba(0, 87, 173, 0.7);
  padding: 15px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.praQuem {
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 15vw;
  padding: 1rem 1vw;
}

.visaoResgate {
  width: 100%;
}
