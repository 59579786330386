.steps {
  display: flex;
  overflow: hidden;
  line-height: 1.5;
  border: 2px solid transparent;
  padding: 0;
  margin: 0;
  border-radius: 5px;
}
.step {
  flex: 1;
  color: #414141;
  text-decoration: none;
  padding: 10px 0 10px 45px;
  background: #fff;
  position: relative;
  display: flex;
  justify-content: center;
}
.step:first-child {
  padding-left: 15px;
}
.step:last-child {
  padding-right: 15px;
}
.step.active {
  background-color: #414141;
  color: #fff;
}
.step.active:after {
  border-left-color: #414141;
}
.step:after {
  content: ' ';
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 30px solid #fff;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  left: 100%;
  z-index: 2;
}
.step:before {
  content: ' ';
  display: block;
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 30px solid #fff;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  margin-left: 2px;
  left: 100%;
  z-index: 1;
}

.done {
  background-color: var(--step-color);
  color: #fff;
  font-weight: bold;
  font-size: 14px;
}
.done:after {
  border-left-color: var(--step-color);
}
