.containerInfoPedido {
  padding: 2rem 10rem;
  display: grid;
  gap: 2rem;
}

.showPrint {
  display: none;
}

.listItemPrint {
  display: grid;
  grid-template-columns: 35% 10% 57%;
  grid-gap: 2%;
  align-items: center;
  margin-bottom: 5pt;
  margin-top: 5pt;
}
