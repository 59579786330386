.containerPerfil {
  margin-left: 1vw;
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-column-gap: 1vw;
}

.imageProfile {
  display: grid;
  grid-template-columns: 13% 87%;
  align-items: center;
}

.containerSMS {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.containerUltimasCompras {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}
.timelineVendasTabelaHeader {
  display: grid;
  grid-template-columns: repeat(5, 19%);
  gap: 0.5vw;
  text-align: center;
}
.timelineVendasTabelaDetalhes {
  display: grid;
  grid-template-columns: repeat(5, 19%);
  gap: 0.5vw;
  text-align: center;
}
.timelineVendasTabelaDetalhes > span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.timelineCampanhasTabelaHeader {
  display: grid;
  grid-template-columns: auto 30%;
  gap: 0.5vw;
  text-align: center;
}
.timelineCampanhasTabelaDetalhes {
  display: grid;
  grid-template-columns: auto 30%;
  gap: 0.5vw;
  text-align: center;
}
.timelineCampanhasTabelaDetalhes > span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dadosPessoais {
  display: grid;
  grid-template-columns: 18% 22% 15% 17% 15%;
  gap: 2%;
  margin-left: 2vw;
}

.containerGraficos {
  display: grid;
  grid-template-columns: repeat(3, 33%);
}

.textGastos {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: limegreen;
}

.textGastos > strong {
  font-size: 20pt;
}
.textGastos > span {
  font-size: 10pt;
}
