.ds-dropzone-one {
  border-radius: 10px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-bottom: 15px;
  border-width: 0.7px;
  background-color: white !important;
}

.ds-text {
  font-size: 20 !important;
  color: #727272;
  margin-left: 20px;
  margin-right: 20px;
  font-weight: 500;
}

.ds-dropzone-two {
  border-width: 0.7px;
  padding: 16px;
  background-color: #fff !important;
}

.ds-dropzone-two.readonly {
  pointer-events: none;
  background-color: transparent !important;
}

.ds-dropzone-two.readonly [class*="DropzoneArea-dropzoneTextStyle-"] {
  display: none;
}
