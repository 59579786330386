.semResultado {
  color: #90a4ae;
  font-size: 20px;
  text-align: center;
}

.botaoOrdena {
  background: none;
  border: none;
  outline: none;
}

.inputFiltro {
  margin-bottom: 1em;
  display: flex;
  justify-content: flex-end;
}
