.containerLoader {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1060;
}

#loader {
  width: 100px;
  height: 100px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: fixed;
  top: 50%;
  left: 50%;
}

.one {
  width: 170px;
  height: 170px;
  border: 5px solid transparent;
  position: absolute;
  border-radius: 50%;
  border-right: 5px solid turquoise;
  -webkit-animation: spin-right 2s linear infinite;
  animation: spin-right 2s linear infinite;
}

.two {
  width: 150px;
  height: 150px;
  border: 5px solid transparent;
  position: absolute;
  border-radius: 50%;
  margin: 10px;
  border-bottom: 5px solid orchid;
  -webkit-animation: spin-left 1s linear infinite;
  animation: spin-left 1s linear infinite;
}

.three {
  width: 120px;
  height: 120px;
  border: 5px solid transparent;
  position: absolute;
  border-radius: 50%;
  margin: 20px;
  border-top: 5px solid white;
  -webkit-animation: spin-right 1s linear infinite;
  animation: spin-right 1s linear infinite;
}

@keyframes spin-left {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

@keyframes spin-right {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
