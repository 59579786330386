.bytes {
  font-size: 11px !important;
  padding-right: 3px !important;
  color: rgba(0, 0, 0, 0.45) !important;
}

.bytes::before {
  content: '\2022';
  display: inline-block;
  margin: 0 4px;
}

.pages {
  font-size: 11px !important;
  padding-right: 3px !important;
  color: rgba(0, 0, 0, 0.45) !important;
}

.pages::after {
  content: '\2022';
  display: inline-block;
  margin: 0 4px;
}

a.nostyle:link {
  text-decoration: inherit;
  color: inherit;
}

a.nostyle:visited {
  text-decoration: inherit;
  color: inherit;
}
