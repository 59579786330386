.addEnderecoLoja {
  display: grid;
  grid-template-columns: repeat(3, 32%);
  row-gap: 4vh;
  column-gap: 2%;
}

.addValoresLoja {
  display: grid;
  grid-template-columns: repeat(2, 49%);
  row-gap: 4vh;
  column-gap: 2%;
}
