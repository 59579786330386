.modal-90w {
  width: 90%;
}
.template {
  width: 100%;
  display: flex;
  justify-content: 10px;
  position: initial;
}
.modal-title {
  font-size: 18;
  font-weight: bold;
}
.modal-header {
  font-size: 12pt;
  display: grid;
  grid-template-columns: repeat(3, 20%);
  margin-top: 2vh;
  color: #605a5a;
}

.modal-subtitle {
  font-weight: 400;
  color: #a2a2a2;
}

.modal-subtitle > strong {
  color: #000;
}

.user-data-panel {
  display: flex;
  flex: 1;
  flex-direction: row;
}
.chart-panel {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
}
.user-section > strong {
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 14px;
}
.user-section {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-right: 5px;
  padding-left: 5px;
}
.shopping-section {
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: center;
  padding-right: 5px;
  padding-left: 5px;
}
.shopping-text {
  color: #76ff03;
  font-size: 24px;
  font-weight: bold;
}
.shopping-section > span {
  color: #76ff03;
  font-size: 12;
  font-weight: bold;
}
.graph {
  margin-top: 10px;
  margin-bottom: 10;
}

.chart-Card {
  display: grid;
  grid-template-columns: repeat(3, 30%);
  gap: 5%;
  justify-content: center;
}

.contador {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
