.ulBreadcrumbs {
  margin: 0;
  padding: 0;
  list-style-type: none;
  overflow: hidden;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
}

.liBreadcrumbs {
  text-align: center;
  line-height: 45px;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 2px solid #3e5e9a;
  font-size: 20px;
  background: #fff;
  color: #3e5e9a;
  cursor: pointer;
}
