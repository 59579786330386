.div-container-sidebar {
  font-size: 13px;
}

.ul-sidebar {
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #0064ad;
}

.ul-sidebar-nivel2 {
  margin-left: 15px;
  padding: 0;
}

.ul-sidebar-nivel3 {
  margin-left: 20px;
  padding: 0;
}

.linha-sidebar {
  list-style-type: none;
  padding: 10px 10px 10px 15px;
}

.linha-sidebar > span > i {
  color: #fff;
  font-size: 22px;
}

.linha-sidebar-sub {
  cursor: pointer;
  list-style-type: none;
  padding: 7px 0;
  display: flex;
  align-items: center;
}

.linha-sidebar-sub > span > i {
  color: #fff;
  margin-right: 10px;
}

.span-sidebar {
  display: grid;
  grid-template-columns: 20% 55% 20%;
  align-items: center;
  cursor: pointer;
}
