.semTemplate {
  background-color: rgb(233, 236, 239);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 30pt;
  color: darkgray;
  border-radius: 5px;
  width: 100%;
  max-width: 100%;
}

