.swal2-title {
  color: rgb(54, 50, 94) !important;
  text-transform: uppercase;
}

.swal2-styled.swal2-cancel {
  color: rgb(54, 50, 94) !important;
  background: transparent !important;
  font-weight: 500;
}
