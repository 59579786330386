@media only screen and (max-height: 850px) {
  .PreviewFinalImage {
    width: 400px !important;
  }
}

@media only screen and (max-height: 760px) {
  .PreviewFinalImage {
    width: 370px !important;
  }
}

