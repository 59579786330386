.swiper-pagination-bullet-active {
  background-color: #36325e !important;
}

.dsCampaignPreviewCarrossel:not(.onlyOneCard):hover .swiper-button-next,
.dsCampaignPreviewCarrossel:not(.onlyOneCard):hover .swiper-button-prev {
  visibility: visible;
  transition: all 0.6s ease;
  transform: scale(1);
}

.dsCampaignPreviewCarrossel .swiper-button-next,
.dsCampaignPreviewCarrossel .swiper-button-prev {
  color: #36325e !important;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  width: 38px;
  height: 38px;
  transition: all 0.6s ease;
  visibility: hidden;
  transform: scale(0.2);
}

.dsCampaignPreviewCarrossel .swiper-button-next:hover,
.dsCampaignPreviewCarrossel .swiper-button-prev:hover {
  transition: all 0.6s ease;
  transform: scale(1.2) !important;
}

.dsCampaignPreviewCarrossel .swiper-button-next:active,
.dsCampaignPreviewCarrossel .swiper-button-prev:active {
  transition: all 0.6s ease;
  transform: scale(0.8) !important;
}

.dsCampaignPreviewCarrossel .swiper-button-next:after,
.dsCampaignPreviewCarrossel .swiper-button-prev:after {
  font-size: 1rem !important;
  font-weight: 900 !important;
}

