.containerInfo {
  display: grid;
  grid-template-columns: repeat(3, 33.3%);
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-top: 2vh;
}

.containerInfoCelula {
  border-bottom: 1px solid lightgray;
  border-left: 1px solid lightgray;
  padding: 0.5em;
  align-items: center;
}

.modalConfig {
  display: grid;
  grid-template-columns: repeat(2, 49%);
  gap: 2%;
  margin-bottom: 50px;
}

.containerCorClube {
  cursor: pointer;
  padding: 0.3em;
  border-radius: 5px;
  text-align: center;
  color: #fff;
  font-weight: 600;
}

.imgLogoClube {
  width: 3.75em;
  height: 3.75em;
  border-radius: 10px;
}

.datasRecomendacoes {
  display: grid;
  grid-template-columns: 49% 49%;
  gap: 2%;
  text-align: center;
  margin-top: 2vh;
}

.listaRecomendacoes {
  display: grid;
  grid-template-columns: repeat(5, 20%);
  text-align: center;
}

.descricaoLista {
  overflow: hidden;
  text-overflow: ellipsis;

  height: 4em;
}

#uncontrolled-tab-example > .nav > li {
  font-weight: bold;
}
