::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-track {
  background: hsla(0, 0%, 100%, 0.2);
}

#HW_frame_cont {
  top: 9.5vh !important;
  right: 5% !important;
  left: inherit !important;
}

div[disabled] {
  pointer-events: none;
  opacity: 0.3;
}

.wg-default,
.wg-default .country-selector {
  bottom: 90px !important;
}

#octadesk-octachat-appchat {
  bottom: 90px;
}

@media print {
  * {
    background: transparent !important;
    color: #000 !important;
    text-shadow: none !important;
    filter: none !important;
    -ms-filter: none !important;
  }

  body {
    margin: 0;
    padding: 0;
    line-height: 1.4em;
  }

  .containerInfoPedido {
    padding: 20pt !important;
  }

  .removePrint {
    display: none !important;
  }

  .showPrint {
    display: inline !important;
  }
}

