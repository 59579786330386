.swiper-pagination-bullet-active {
  background-color: #000000 !important;
}

.dsCampaignPreviewCarrossel:not(.onlyOneCard):hover .swiper-button-next,
.dsCampaignPreviewCarrossel:not(.onlyOneCard):hover .swiper-button-prev {
  visibility: visible;
  transition: all 0.2s ease;
  transform: scale(1);
}

.dsCampaignPreviewCarrossel .swiper-button-next,
.dsCampaignPreviewCarrossel .swiper-button-prev {
  color: #ffffff !important;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  width: 10px;
  height: 10px;
  transition: all 0.5s ease;
  visibility: hidden;
  transform: scale(0.2);
}
j
.dsCampaignPreviewCarrossel .swiper-button-next:hover,
.dsCampaignPreviewCarrossel .swiper-button-prev:hover {
  transition: all 0.3s ease;
  transform: scale(1.2) !important;
}

.dsCampaignPreviewCarrossel .swiper-button-next:active,
.dsCampaignPreviewCarrossel .swiper-button-prev:active {
  transition: all 0.2s ease;
  transform: scale(0.8) !important;
}

.dsCampaignPreviewCarrossel .swiper-button-next:after,
.dsCampaignPreviewCarrossel .swiper-button-prev:after {
  font-size: 1rem !important;
  font-weight: 900 !important;
}

