.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-container {
  display: flex;
  width: 50px;
  flex-wrap: wrap;
}

.circleC {
  position: relative;
  width: calc(50px / 2);
  height: calc(50px / 2);
  opacity: 0;
}
.circleC::before {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.circle-1 {
  animation: animate-circle 2s ease-in-out infinite;
}
.circle-1::before {
  background: #9b59b6;
  bottom: calc(-20px / 2);
  right: calc(-20px / 2);
}
.circle-2 {
  animation: animate-circle 2s ease-in-out infinite;
}
.circle-2::before {
  background: #3498db;
  bottom: calc(-20px / 2);
  left: calc(-20px / 2);
}
.circle-3 {
  animation: animate-circle 2s ease-in-out infinite;
}
.circle-3::before {
  background: #e67e22;
  top: calc(-20px / 2);
  right: calc(-20px / 2);
}
.circle-4 {
  animation: animate-circle 2s ease-in-out infinite;
}
.circle-4::before {
  background: #f1c40f;
  top: calc(-20px / 2);
  left: calc(-20px / 2);
}

@keyframes animate-circle {
  0% {
    transform: rotate(0);
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    transform: rotate(360deg);
    opacity: 0;
  }
}
